<template>
  <div class="sljr-render">
    <Breadcrumb></Breadcrumb>
    <!-- 一级菜单下面所拥有的二级菜单 -->
    <el-container>
      <el-aside width="228px" v-if="isShowLeftMenu">
        <SideMenu :items="items"></SideMenu>
      </el-aside>

      <!-- 以及二级菜单所对应的页面 -->
      <el-main
        class="conten-box"
        :style="{ 'padding-left': isShowLeftMenu ? '32px' : 0 }"
      >
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import SideMenu from '@/components/SideMenu/SideMenu';

export default {
  components: {
    SideMenu
  },
  data () {
    return {
      isShowLeftMenu: true,//是否显示左边菜单
      items: [
        {
          index: 'mechanism',
          title: '机构动态'
        },
        {
          index: 'project',
          title: '项目动态'
        },
        {
          index: 'album',
          title: '活动图集'
        }
      ],

    }
  },
  created () {
    if (this.$route.name === "AlbumArticle") {
      this.isShowLeftMenu = false;
    }
  },
  methods: {
  },
  watch: {
    $route (n) {
      if (n.name === "AlbumArticle") {
        this.isShowLeftMenu = false;
      } else {
        this.isShowLeftMenu = true;
      }
    }
  }
}
</script>

<style scoped lang='scss'>
</style>